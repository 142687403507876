<template>
  <section :class="{'form-group': true, row: !!label }">

    <div class="col-sm-4" v-if="label">
    <InputLayout
      :input-id="$static.input.id"
      :label="label"
      :subtext="subtext"
      :arrangement="arrangement"
      :error="error"
    > </InputLayout>
    </div>
    <div :class="(label) ? 'col-sm-8': ''">
      <input
        class="Input__input"
        ref="input"
        :type="type"
        :value="value"
        :placeholder="placeholder"
        :disabled="disableInput"
        :id="$static.input.id"
        :name="$static.input.id"
        :class="inputClass"
        @input="checkInputValidStatusAndEmitChanges($event)"
        @keydown.enter="eventEmitterSubmit($event)"
        @keydown="filterInput($event)"
        autocomplete="chrome-off"
        v-bind="{...$props, ...$attrs}"
      />
    </div>
  </section>
</template>

<script>
import InputLayout from './components/InputLayout.component';
import InputMixin from './mixins/input.mixin';
import LayoutMixin from './mixins/layout.mixin';
import includes from 'lodash.includes';

export default {
  name: 'DDMCAF-Input',
  components: {InputLayout},
  mixins: [InputMixin, LayoutMixin],
  props: {
    type: {type: String, default: 'text', validator: value => includes(['text', 'number', 'url', 'email', 'password'], value)},
    value: {type: null, default: ''},
    placeholder: {type: String, default: ''},
    disableInput: {type: Boolean, default: false},
    inputClass: {type: [String, Array, Object], default: ''},
  },
  data: () => ({ validation: null }),
  mounted() {
    this.checkInputValidationStatus();
  },
  methods: {
    checkInputValidationStatus() {
      this.validation = this.$refs.input.validity.valid;
      return this;
    },
    eventEmitterValidStatus() {
      const {id} = this.$static.input;
      const {validation} = this;
      this.$emit('validation', {id, validation});
      return this;
    },
    filterInput(event) {
      if (this.type === 'number') {
        const {key} = event;
        const isBackspace = new RegExp('backspace', 'i').test(key);
        const isNumber = new RegExp('[0-9]').test(key);
        const isArrow = new RegExp('arrow', 'i').test(key);
        const isEnter = new RegExp('enter', 'i').test(key);

        const valid = [isBackspace, isNumber, isArrow, isEnter].some(status => status === true);
        if (!valid && key !== 'Tab') event.preventDefault();
      }
    },
    checkInputValidStatusAndEmitChanges(event) {
      this
        .checkInputValidationStatus()
        .eventEmitterValidStatus()
        .eventEmitterInput(event);
    }
  }
}
</script>

<style lang="scss">
.Input {
  &__input {
    padding: 12px 16px 12px 16.5px;
    width: 100%;
    font-size: 12px;
    line-height: 17px;
    border-radius: 12px;
    border: 1px solid #D8DAE3;
    font-weight: 700;
    color: #383838;
    &::placeholder {
      color: #AFB1BC;
      font-weight: 600;
    }
  }
}
</style>
